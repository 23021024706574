import React from 'react';
import ImageCarousel from './ImageCarousel';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MdOpenInNew } from 'react-icons/md';

const ProjectsModal = React.forwardRef((props, ref) => {
    return (
        <Modal
            {...props}
            ref={ref}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ImageCarousel images={props.data.carouselImages} />
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    {/* Modal heading */}
                    <span className="modal-title-name">{props.data.name}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <h4>Centered Modal</h4> */}
                <span className="modal-body-desc"><p style={{ whiteSpace: 'pre-line', lineHeight: '1.7' }}>
                    {props.data.description}
                </p></span>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" href={props.data.webSite} target="_blank">
                    <MdOpenInNew style={{ verticalAlign: 'middle', marginRight: '3px' }} /> View Site
                </Button>
                <button type="button" className="close" onClick={props.onHide}>
                    <span aria-hidden="true">x</span>
                    <span className="sr-only">Close</span>
                </button>
            </Modal.Footer>
        </Modal>
    );
})

export default ProjectsModal;