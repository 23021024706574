import React, { useRef } from 'react';
import Video from '../assets/video.mov';
import CoverImg from '../assets/cover.jpg';
import { FiArrowRightCircle } from 'react-icons/fi';
import {CgPlayButtonO} from 'react-icons/cg';
import { Link } from 'react-scroll';
import { useState } from 'react';

const LandingPage = () => {
    // To change offset value (additional px) based on screen size
    const isMobile = window.innerWidth <= 700;
    const offset = isMobile ? -56 : 0;

    const playerRef = useRef(null);
 
    const [suspendIsOn, setSuspendIsOn] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);

    // useEffect(() => {
    //         console.log('suspendIsOn = ', suspendIsOn);
    //         console.log('isPlaying = ', isPlaying)
    // }, [suspendIsOn, isPlaying])
    
    return (
        <section className="showcase" id="landing-page">
            <div className="video-container">
                <video ref={playerRef} src={Video} autoPlay playsInline preload='metadata' muted loop poster={CoverImg} controls='hidden'
                    // onSuspend={() => {setSuspendIsOn(true); setIsPlaying(false)}} 
                    onPlay={() => {setSuspendIsOn(false)}} 
                    onTimeUpdate={() => {setIsPlaying(true)}}>
                </video>                     
            </div>
        {/* If suspend event is detected (aka low power mode on iOS devices) then display the Play button, else hide it. */}
            { (suspendIsOn && !isPlaying) ?  
                <div id='videoControls'>
                    <CgPlayButtonO onClick={() => {playerRef.current.play()}} size="3.8em" style={{color: 'white', opacity: '.5'}}/>
                </div> 
            : null }
            <div className="content">
                <h1>Hello, I'm <span style={{ color: 'orange' }}>Daler Bobojanov</span>.</h1>
                <h2>I'm a Full-Stack Developer.</h2>
                <div className="btn">
                    <Link
                        role="button"
                        activeClass="active"
                        to="about"
                        spy={true}
                        smooth={true}
                        // offset={-70}
                        offset={offset}
                        duration={400}
                    >
                        Take a look around <FiArrowRightCircle className="arrow" size="1.4em" />
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default LandingPage;
