import React from 'react';
import ProjectsModal from './ProjectsModal';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import MarioGame from '../assets/RPGame.png';
import RPGame_pic1 from '../assets/RPGame_pic1.png';
import RPGame_pic2 from '../assets/RPGame_pic2.png';
import RPGame_pic3 from '../assets/RPGame_pic3.png';
import YourSpace from '../assets/YourSpace.png';
import YSpace_pic1 from '../assets/YSpace_pic1.png';
import YSpace_pic2 from '../assets/YSpace_pic2.png';
import YSpace_pic3 from '../assets/YSpace_pic3.png';
import Eat_Read_JobHunt from '../assets/Eat_Read_JobHunt.png';
import ERH_pic1 from '../assets/ERH_pic1.png';
import ERH_pic2 from '../assets/ERH_pic2.png';
import ERH_pic3 from '../assets/ERH_pic3.png';
import WeatherForecast from '../assets/WeatherForecast.png';
import WF_pic1 from '../assets/WF_pic1.png';
import WF_pic2 from '../assets/WF_pic2.png';
import WF_pic3 from '../assets/WF_pic3.png';
import WineAPI from '../assets/WineAPI.png';
import WineAPI_pic1 from '../assets/WineAPI_pic1.png';
import WineAPI_pic2 from '../assets/WineAPI_pic2.png';
import WineAPI_pic3 from '../assets/WineAPI_pic3.png';

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            modalShow: false,
            userSelection: {},
            projects: [
                {
                    id: 1,
                    image: MarioGame,
                    name: 'RPG Game\n"Super Mario"\n+\n"Rock, Paper, Scissors"',
                    technologyUsed: 'JavaScript / HTML / CSS',
                    description: `"Mario" meets "Spiny" in "Rock, Paper, Scissors" game battle..
                    This is a two player hybrid game that resembles "Super Mario", that also incorporates classic "Rock, Paper, Scissors" game.`,
                    webSite: 'https://daler-bobojanov.github.io/RPGame-Rock,%20Paper,%20Scissors/',
                    carouselImages: [RPGame_pic1, RPGame_pic2, RPGame_pic3]
                },
                {
                    id: 2,
                    image: YourSpace,
                    name: 'YourSpace',
                    technologyUsed: 'React JS / Java / PostgreSQL',
                    description: `"YourSpace" - is a consolidated place to manage your organization documents and team members individual tasks.
                    This full-stack CRUD web-application helps organizations and their department managers to create documents/training materials for their teams and maintain its lifecycle. It also incorporates personalized "DAILY TO-DO" functionality to help keep track of your progress in achieving desired goals. Each session is secured by user authentication.`,
                    webSite: 'https://welcome-to-your-space.herokuapp.com/',
                    carouselImages: [YSpace_pic1, YSpace_pic2, YSpace_pic3]
                },
                {
                    id: 3,
                    image: Eat_Read_JobHunt,
                    name: 'Eat-Read-JobHunt',
                    technologyUsed: 'React JS / Bootstrap',
                    description: `This was a group project between two other developers where I also played the Team Lead role.
                    This app incorporates three areas of people's' life and can help the user with:
                    a) helps job seeking software developers search for jobs in Software Development/IT field. It allows you to filter your search by different search parameters (e.g city, state, title, benefits, FT/PT, etc.).
                    b) it also helps you find recipes of your choice. Due to COVID-19 pandemic and "Stay-Home" order, site visitors can take advantage of this app to find recipes from various cultures and cuisines.
                    c) lastly, the app provides books search engine to satisfy any reader. Users also given the ability to save the book they like to the shortlist.`,
                    webSite: 'http://eat-read-hunt.surge.sh/',
                    carouselImages: [ERH_pic1, ERH_pic2, ERH_pic3]
                },
                {
                    id: 4,
                    image: WeatherForecast,
                    name: 'Weather Forecast',
                    technologyUsed: 'React JS',
                    description: `Dealing with the weather is hard enough. Seeing the forecast should be easy.
                    This web-application designed with intuitive User Interface in-order to enhance User Experience. The app helps you to find out the weather condition in your area or at the place where you plan on to travel.`,
                    webSite: 'http://weather-app-api.surge.sh',
                    carouselImages: [WF_pic1, WF_pic2, WF_pic3]
                },
                {
                    id: 5,
                    image: WineAPI,
                    name: 'Wine API',
                    technologyUsed: 'React JS',
                    description: `This web-app seeds dummy data from a 3rd party API and renders it on the page. The user can add or delete wines of their choice (max 10 items).
                    It was created as a practical tool to exercise React JS data rendering and HTTP methods.`,
                    webSite: 'http://wine-api.surge.sh/',
                    carouselImages: [WineAPI_pic1, WineAPI_pic2, WineAPI_pic3]
                }
            ]
        };
    }

    render() {
        // For large screen resolutions
        const projectList = this.state.projects.map((project, id) => {
            return <div className="image-container" key={id} >
                <img src={project.image} alt={project.name} style={{ width: '100%' }} className="image" key={project.id}></img>
                <div className="middle" >
                    <div className="text" >
                        <span style={{ whiteSpace: 'pre-line' }}>{project.name}</span>
                        <hr color='white' />
                        <span className="technology-used">{project.technologyUsed}</span>
                        <br />
                        <button className="btn btn-projects" onClick={() => this.setState({ modalShow: true, userSelection: project })} >LEARN MORE</button>
                    </div>
                </div>
            </div>
        });

        {/* For small screens resolution */ }
        const projectCard = this.state.projects.map(item => {
            return <Card style={{ width: '18rem', margin: '20px auto' }} bg="light" border="dark" key={item.id} className="">
                <Card.Img variant="top" src={item.image} />
                <Card.Body>
                    <Card.Title style={{ fontSize: '16px' }} className="card-title">{item.name}</Card.Title>
                    <hr />
                    <Card.Text>
                        <span className="technology-used">{item.technologyUsed}</span>
                    </Card.Text>
                    <Button variant="primary" size="sm" onClick={() => this.setState({ modalShow: true, userSelection: item })} >Learn more</Button>
                </Card.Body>
            </Card>;
        })

        return (
            // For large screen resolutions
            <section id="projects" >
                <div className="wrapper flex">
                    <div className="headings slide-from-right">Projects</div>
                    <div className="header-bar slide-from-left" ></div>
                </div>
                <div className="portfolio">
                    {projectList}
                    <ProjectsModal
                        data={this.state.userSelection}
                        ref={this.modalRef}
                        show={this.state.modalShow}
                        onHide={() => this.setState({ modalShow: false })}
                    />
                </div>

                {/* For small screen and tablet resolutions */}
                <div className="project-card">
                    {projectCard}
                </div>
            </section>
        );
    }
}

export default Projects;



