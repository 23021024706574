import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

const ImageCarousel = (props) => {
    return (
        <Carousel
            {...props}
            interval={1500}
        >
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={props.images[0]}
                    alt="First slide"
                    max-width="100%"
                    height="auto"
                />
                {/* <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={props.images[1]}
                    alt="Second slide"
                    max-width="100%"
                    height="auto"
                />
                {/* <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={props.images[2]}
                    alt="Third slide"
                    max-width="100%"
                    height="auto"
                />
                {/* <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                </Carousel.Caption> */}
            </Carousel.Item>
        </Carousel>
    );
}

export default ImageCarousel;
