import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ContactModal = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span id="contact-modal-title">The message has been sent</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 style={{ textAlign: 'center' }}>Thank you for your message!</h4> <br />
                <p>It will be reviewed, and I will get back to you in a timely manner.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default ContactModal;
