import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-scroll';

const NavigationBar = () => {
    // To control Navbar expanded behaviour on smaller screens
    const [expanded, setExpanded] = React.useState(false);

    // To change offset value (additional px) based on screen size
    const isMobile = window.innerWidth <= 700;
    const offset = isMobile ? -245 : 0;

    return (
        < Navbar expanded={expanded} collapseOnSelect expand="md" variant="dark" sticky="top" >
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" >
                {/* <Navbar.Brand href="#home">Home</Navbar.Brand> */}
                <Container>
                    <Nav className="mr-auto nav-items">
                        <Link
                            className="nav-link"
                            activeClass="active"
                            to="landing-page"
                            spy={true}
                            smooth={true}
                            // offset={-70}
                            duration={600}
                            onClick={() => setExpanded(false)}
                        >
                            Home
                        </Link>
                        <Link
                            className="nav-link"
                            activeClass="active"
                            to="section-about"
                            spy={true}
                            smooth={true}
                            // offset={-255}
                            offset={offset}
                            duration={600}
                            onClick={() => setExpanded(false)}
                        >
                            About
                        </Link>
                        <Link
                            className="nav-link"
                            activeClass="active"
                            to="skills"
                            spy={true}
                            smooth={true}
                            // offset={-255}
                            offset={offset}
                            duration={600}
                            onClick={() => setExpanded(false)}
                        >
                            Skills
                        </Link>
                        <Link
                            className="nav-link"
                            activeClass="active"
                            to="projects"
                            spy={true}
                            smooth={true}
                            // offset={-255}
                            offset={offset}
                            duration={600}
                            onClick={() => setExpanded(false)}
                        >
                            Portfolio
                        </Link>
                        <Link
                            className="nav-link"
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            // offset={-70}
                            offset={offset}
                            duration={600}
                            onClick={() => setExpanded(false)}
                        >
                            Contact
                            </Link>
                    </Nav>
                </Container>
            </Navbar.Collapse>
        </Navbar >
    );
}

export default NavigationBar;
