// Images
import AWS from '../assets/img/aws-cda.png';
import Terraform from '../assets/img/terraform.png';
import Docker from '../assets/img/docker.png';
import K8s from '../assets/img/k8s.png';
import HTML5 from '../assets/img/html5.png';
import JSX from '../assets/img/JSX.png';
import CSS3 from '../assets/img/CSS3.png';
import Bootstrap from '../assets/img/Bootstrap.png';
import SASS from '../assets/img/SASS.png';
import React_Bootstrap from '../assets/img/React_Bootstrap.png';
import Materialize from '../assets/img/Materialize.png';
import React_img from '../assets/img/React.png';
import JS from '../assets/img/JavaScript.png';
import jQuery from '../assets/img/jQuery.png';
import NodeJS from '../assets/img/Node.png';
import Redux_img from '../assets/img/Redux.png';
import Java from '../assets/img/java.png';
import SpringBoot from '../assets/img/SpringBoot.png';
import postgreSQL from '../assets/img/postgreSQL.png';
import mongoDB from '../assets/img/mongoDB.png';
import Webpack from '../assets/img/webpack.png';
import Github from '../assets/img/github.png';
import Heroku from '../assets/img/heroku.png';
import Firebase from '../assets/img/firebase.png';
import AdobeXD from '../assets/img/adobeXD.png';
import Netlify from '../assets/img/netlify.png';

const Skills = () => {    
    return (
        <section id="skills">
            <div className="wrapper flex">
                <div className="headings slide-from-right">Skills</div>
                <div className="header-bar slide-from-left"></div>
            </div>
            <div className="container parent">
                {/* CLOUD */}
                <div className="card">
                    <div className="card-content">
                        <h4 className="brown-text light">CLOUD</h4>
                        <div className="row text-center">
                            <div className="col">
                                <img width="120px" height="120px" src={AWS} alt="aws-certified-developer-associate icon" />
                                <a href="https://www.credly.com/badges/f60c7e3c-9010-4dd7-bbab-e66cd407d985/public_url" target="_blank" rel="noreferrer" className="tech-name anchor">Verify</a>
                            </div> 
                            <div className="col">
                                <img src={Terraform} alt="terraform icon" className="tech-img" /><span className="tech-name">Terraform</span>
                            </div>
                            <div className="col">
                                <img src={K8s} alt="kubernetes icon" className="tech-img" /><span className="tech-name">Kubernetes</span>
                            </div>
                            <div className="col">
                                <img src={Docker} alt="docker icon" className="tech-img" /><span className="tech-name">Docker</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* HMTL */}
                <div className="card">
                    <div className="card-content">
                        <h4 className="brown-text light">HTML</h4>
                        <div className="row text-center">
                            <div className="col">
                                <img src={HTML5} alt="html5 icon" className="tech-img" /><span className="tech-name">HTML5</span>
                            </div>
                            <div className="col">
                                <img src={JSX} alt="jsx icon" className="tech-img" /><span className="tech-name">JSX</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* CSS */}
                <div className="card">
                    <div className="card-content">
                        <h4 className="brown-text light">CSS</h4>
                        <div className="row text-center">
                            <div className="col">
                                <img src={CSS3} alt="css3 icon" className="tech-img" /><span className="tech-name">CSS3</span>
                            </div>
                            <div className="col">
                                <img src={Bootstrap} alt="bootstrap icon" className="tech-img" /><span className="tech-name">Bootstrap</span>
                            </div>
                            <div className="col">
                                <img src={SASS} alt="sass icon" className="tech-img" /><span className="tech-name">SASS</span>
                            </div>
                            <div className="col">
                                <img src={React_Bootstrap} alt="react-bootstrap icon" className="tech-img" /><span className="tech-name">React-Bootstrap</span>
                            </div>
                            <div className="col">
                                <img src={Materialize} alt="materialize icon" className="tech-img" /><span className="tech-name">Materialize</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* JavaScript */}
                <div className="card">
                    <div className="card-content">
                        <h4 className="brown-text light">JavaScript</h4>
                        <div className="row text-center">
                            <div className="col">
                                <img src={React_img} alt="react icon" className="tech-img" /><span className="tech-name">React</span>
                            </div>
                            <div className="col">
                                <img src={JS} alt="javascript icon" className="tech-img" /><span className="tech-name">JavaScript</span>
                            </div>
                            <div className="col">
                                <img src={jQuery} alt="jQuery icon" className="tech-img" /><span className="tech-name">jQuery</span>
                            </div>
                            <div className="col">
                                <img src={NodeJS} alt="nodejs icon" className="tech-img" /><span className="tech-name">Node</span>
                            </div>
                            <div className="col">
                                <img src={Redux_img} alt="redux icon" className="tech-img" /><span className="tech-name">Redux</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Back-End */}
                <div className="card">
                    <div className="card-content">
                        <h4 className="brown-text light">Back-End</h4>
                        <div className="row text-center">
                            <div className="col">
                                <img src={Java} alt="java icon" className="tech-img" /><span className="tech-name">Java</span>
                            </div>
                            <div className="col">
                                <img src={SpringBoot} alt="springBoot icon" className="tech-img" /><span className="tech-name">Spring Boot</span>
                            </div>
                            <div className="col">
                                <img src={postgreSQL} alt="postgreSQL icon" className="tech-img" /><span className="tech-name">PostgreSQL</span>
                            </div>
                            <div className="col">
                                <img src={mongoDB} alt="mongoDB icon" className="tech-img" /><span className="tech-name">MongoDB</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Other */}
                <div className="card">
                    <div className="card-content">
                        <h4 className="brown-text light">Other</h4>
                        <div className="row text-center">
                            <div className="col">
                                <img src={Webpack} alt="webpack icon" className="tech-img" /><span className="tech-name">Webpack</span>
                            </div>
                            <div className="col">
                                <img src={Github} alt="github icon" className="tech-img" /><span className="tech-name">Github</span>
                            </div>
                            <div className="col">
                                <img src={Heroku} alt="heroku icon" className="tech-img" /><span className="tech-name">Heroku</span>
                            </div>
                            <div className="col">
                                <img src={Netlify} alt="netlify icon" className="tech-img" /><span className="tech-name">Netlify</span>
                            </div>
                            <div className="col">
                                <img src={Firebase} alt="firebase icon" className="tech-img" /><span className="tech-name">Firebase</span>
                            </div>
                            <div className="col">
                                <img src={AdobeXD} alt="adobeXD icon" className="tech-img" /><span className="tech-name">Adobe XD</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;
