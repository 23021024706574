import React from 'react';
import Photo from '../assets/IMG.jpg';
import { FaSearch } from 'react-icons/fa';
import { RiCompasses2Line } from 'react-icons/ri';
import { MdBuild } from 'react-icons/md';
import { IconContext } from 'react-icons/lib';
import Parralax from 'react-rellax';
import { Link } from 'react-scroll';

const About = () => {
     // To change percentage value (additional px/start position) based on screen size
     const isTablet = window.innerWidth >= 667 && window.innerWidth <= 900;
     const percentage = isTablet ? 1.2 : .6;

    return (
        <section className="about" id="section-about">
            <div className="flex-container">
                <div className="about-me-image">
                    <img src={Photo} alt="Daler Bobojanov | Full-Stack Developer" width="600px" />
                </div>
                <div className="wrapper-container">
                    <h2>I want to <span className="bg">make things</span><br />that <span className="underline">make a difference.</span></h2>
                    <div className="about-me">
                        <p>I am an avid learner open to constantly learning new things, and a passionate Software Engineer. <br />
                            I am passionate about building quality software that improves the lives of those around me. Specializing in developing full-stack applications with in-demand technologies, I can offer you an innovative approach to building secure applications.</p>
                        <Parralax centered={true} speed={-2} percentage={percentage} >
                            <p>As a web developer, I enjoy using my obsessive attention to detail, my unequivocal love for making things, and my mission-driven work ethic to literally change the world. That's why I’m excited to make a big impact at a high growth company.</p>
                            <Link
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                duration={600}
                            >
                                Let's create something together.
                            </Link>
                        </Parralax>
                    </div>
                </div>
            </div>

            <div className="section section-grid">
                <IconContext.Provider value={{ size: '3.5em' }}>
                    <Parralax percentage={0} speed={0}>
                        <div className="rellax">
                            <FaSearch className="secondary-text" />
                            <h2>Research<span className="secondary-text dot">.</span></h2>
                            <p>Understand the client’s subject matter completely and build on the knowledge related to it, becoming intimately familiar with the subject matter. Determine methods to address the specific customer’s needs and requirements.</p>
                        </div>
                    </Parralax>

                    <Parralax percentage={1} speed={-1}>
                        <div className="rellax">
                            <RiCompasses2Line className="secondary-text" />
                            <h2>Design<span className="secondary-text dot">.</span></h2>
                            <p>Misleadingly thought of as the superficial appearance of a product, design actually encompasses a lot more. It is a cross functional process that includes market research, technical research, design of a concept, and prototype mockup.</p>
                        </div>
                    </Parralax>

                    <Parralax percentage={1} speed={-2}>
                        <div className="rellax">
                            <MdBuild className="secondary-text" />
                            <h2>Develop<span className="secondary-text dot">.</span></h2>
                            <p>Findings from the research and design phases are utilized for the production of specific products including materials, systems, and methods. Engineer utilizing the research and designs to produce commercial products for the customer.</p>
                        </div>
                    </Parralax>
                </IconContext.Provider>
            </div>
        </section>
    );
}

export default About;
