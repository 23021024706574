import React from 'react';
import ContactModal from './ContactModal';

import { IconContext } from 'react-icons';
import { FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { CgChevronDoubleUpO } from 'react-icons/cg';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { animateScroll as scroll } from 'react-scroll';
import ReCAPTCHA from 'react-google-recaptcha';

// Front Netlify docs
const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

// const _site_key = process.env.SITE_RECAPTCHA_KEY;

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            subject: "",
            message: "",
            validated: false,
            modalShow: false,
            load: false,
            // callback: "not fired",
            // recaptchaLoaded: false,
            // token: "[empty]",
            // expired: false
        }
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ load: true });
          }, 1500);
        // console.log('didMount - reCaptcha Ref-', this._reCaptchaRef);
    }

      // asyncScriptOnLoad = () => {
    //     this.setState({ callback: "called!", recaptchaLoaded: true });
    //     console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    //   };

    handleReCaptchaChange = token => {
        // console.log("onChange prop - Captcha value:", token);
        // console.log('didMount - Current token value is:', this._reCaptchaRef.current.getValue());
        // this.setState({ token });
        if (token === null) {
            this._reCaptchaRef.current.reset();
            // this.setState({ expired: true });
            // console.log('Current token state after Change is:', this.state.token);
        }
    }
    
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        // To Execute re-captcha on form change
        // this._reCaptchaRef.current.execute();
    } 

    pageRefresh = () => {
        window.location.reload(false);
        this.setState({ modalShow: false });
    }

    // Scroll-to-top button
    scrollToTop = () => {
        scroll.scrollToTop();
    }
    
    handleSubmit = e => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            // Marks a form as having been validated. Setting it to true will toggle any validation styles on the forms elements.
            this.setState({ validated: true });
            // console.log(`Modal is set to ${modalShow} from inside if`);
            // console.log(`Validated set to ${validated} from inside if`);
        } else {
            // programatically execute re-captcha challenge
            this._reCaptchaRef.current.executeAsync()
            .then(token => {
                if (token === null) {
                    alert("ERROR! Current session timed out. Please retry.")
                }
                // console.log("executeAsync promise - Captcha token:", token);
                  
                fetch("/?no-cache=1", {
                    method: "POST",
                    headers: { 
                        "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({
                        'form-name': "contact",
                        'g-recaptcha-response': token,
                        'data-sitekey': "6LfARdgZAAAAAP60hQatv0KsE7v_OMTiE34vNYYJ",
                        'name': this.state.name,
                        'email': this.state.email,
                        'subject': this.state.subject,
                        'message': this.state.message,
                    })
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not OK. Make sure you connected to Internet or check your browser "Console" for more details.');
                    }
                    return response.blob();
                })
                .then(() => {
                    this.setState({ modalShow: true });
                })
                .catch(error => {
                    alert(error, "Oops.. Something went wrong while sending your message. Please check your connection and try again.\nIf the error persist, check your browser 'Console' for more details on the error.");
                });
            });
            e.preventDefault();
        };
    };  

    render() {
        const { name, email, subject, message } = this.state;
        const { load } = this.state;
        return (
            <section id="contact">
                <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="90" width="100%" version="1.1" xmlns="http://www.w3.org/200/svg" className="svgcolor-light">
                    <path d="M0 0 L50 100 L100 0 Z" fill="white" stroke="white"></path>
                </svg>
                <div className="wrapper flex">
                    <div className="headings header">Contact</div>
                    <div className="header-bar"></div>
                    <div className="sub-header">Have a question or want to work together?</div>
                </div>

                <Container fluid className="wrapper flex">
                    <Form data-netlify-recaptcha="true" className="contact form" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            
                        <Form.Group controlId="formGroupName">
                            <Form.Label srOnly>Your Name</Form.Label>
                            <Form.Control as="input" required type="text" name="name" value={name} onChange={this.handleChange} placeholder="Enter Your Name" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Please provide a valid name.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formGroupEmail">
                            <Form.Label srOnly>Email</Form.Label>
                            <Form.Control as="input" required type="email" name="email" value={email} onChange={this.handleChange} placeholder="Enter Your Email" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formGroupSubject">
                        <Form.Label srOnly>Subject</Form.Label>
                        <Form.Control as="input" required type="text" name="subject" value={subject} onChange={this.handleChange} placeholder="Subject" />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">Please provide a subject.</Form.Control.Feedback>
                    </Form.Group>

                        <Form.Group controlId="formGroupTextArea">
                            <Form.Label srOnly>Text Area</Form.Label>
                            <Form.Control required as="textarea" rows={5} name="message" value={message} onChange={this.handleChange} placeholder="Your Message" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Please provide some details.</Form.Control.Feedback>
                            <Button size="sm" variant="primary" type="submit" className="button-submit">SEND</Button>
                        </Form.Group>
                        {load && (
                            <ReCAPTCHA
                                className="g-recaptcha-response"
                                sitekey="6LfARdgZAAAAAP60hQatv0KsE7v_OMTiE34vNYYJ"
                                size="invisible"
                                theme="dark"
                                badge="bottomleft"
                                ref={this._reCaptchaRef}
                                onChange={this.handleReCaptchaChange}
                                // asyncScriptOnLoad={this.asyncScriptOnLoad}
                            />
                        )}
                    </Form>
                </Container>
                <ContactModal show={this.state.modalShow} onHide={this.pageRefresh} />

                <footer id="footer" className="wrapper flex">
                    <IconContext.Provider value={{ size: '2.8em' }}>
                        <div id="scroll-up"><CgChevronDoubleUpO id="scroll-to-top" onClick={this.scrollToTop} /></div>
                        <div className="social-icons spinned">
                            <a href="https://github.com/daler-bobojanov" target="_blank" rel="noopener noreferrer"><FaGithub className="icon github" title="GitHub icon" /></a>
                            <a href="https://www.linkedin.com/in/daler-bobojanov" target="_blank" rel="noopener noreferrer"><FaLinkedin className="icon linkedin" title="Linkedin icon" /></a>
                            <a href="https://www.instagram.com/codewith_dbo/" target="_blank" rel="noopener noreferrer"><FaInstagram className="icon instagram" title="Instagram icon" /></a>
                        </div>
                        <div id="copyright">
                            <div id="footnote">DALER BOBOJANOV <span id="highlight"> &copy; 2020 - 2022</span></div >
                        </div>
                    </IconContext.Provider >
                </footer >
            </section>
        );
    }
}

export default Contact;